import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Outfit', sans-serif;
}
  body {
    background-color: #050d59;
    background-image: url('https://ultrapad.finance/static/media/hero-banner-img.d24a1b2a.png');

    img {
      height: auto;
      max-width: 100%;
    }
  }
  nav {
    height: 108px !important;
    background: #1f1d3d !important;
  }
  div[height="56"] {
    top: 0;
    height: 108px;
  }
  div#root > div > div:last-child > div > div:last-child {
    position: relative;
    background: none !important;
  
  }
  div[height="56"] +div >div:first-child {
    // background-color: #050d59;
    margin-top: 50px;
  }
  div[height="56"] +div + div > div> div:first-child {
    // background: #1f1d3db3  !important;
  }
  nav a {
    color: #fff !important;
  }
  nav a:hover {
    background: none !important;
      border-radius: 8px !important;
      color: #ff7179 !important;
  }
  #join-pool-button,#swap-page > div:last-child button,nav >div:last-child > button,#add-liquidity-input-tokena  + button,#add-liquidity-input-tokenb  + button{
    background-image: linear-gradient(90deg,#7a0fff 0,#46bdf4);
      font-size: 16px;
      line-height: 20px;
      color: #fff;
      padding: 19px 34px;
      border: 0!important;
      border-radius: 8px;
      font-weight: 700;
  }
  [color="secondary"] {
    color: #ff7179 !important;
  }
  [color="primary"]{
    color: #635dfa !important;
  }
  div#root > div > div:last-child > div > div:last-child ul {
    display: none;
  }
  
  div#root > div > div:last-child > div > div:last-child >div > div:last-child {
    display: none;
  }
  
  div#root > div > div:last-child > div > div:last-child >div {
    flex-direction: revert;
    justify-content: space-between;
  }
  
  div#root > div > div:last-child > div > div:last-child >div >div {
    margin: 0;
    padding: 0;
    border: none;
  }
  div#root > div > div:last-child > div > div:last-child >div >div:nth-child(2) div{
    background-image: url(/images/logo.svg) !important;
    width: 160px;
    height: 60px;
    background-size: 100% 100% !important;
  }
  div#root > div > div:last-child > div > div:last-child >div >div:nth-child(2) div svg{
    display: none;
  }
  nav a[aria-label="Pancake home page"] {
    background-image: url(/images/logo.svg) !important;
    width: 160px;
    height: 60px;
    background-size: 100% 100% !important;
}
nav a[aria-label="Pancake home page"] svg {
  display: none !important;
}
`

export default GlobalStyle
